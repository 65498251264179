import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { DashboardContent, DashboardSurvey, DashboardTopic } from 'components/dashboard';
import SEO from 'components/SEO';

export default function dashboard() {
  const {
    allSurvey: { nodes: surveys },
    allTopic: { nodes: topics },
  } = useStaticQuery<{
    allSurvey: {
      nodes: Array<DashboardSurvey>;
    };
    allTopic: {
      nodes: Array<DashboardTopic>;
    };
  }>(graphql`
    query {
      allTopic(sort: { fields: id, order: ASC }) {
        nodes {
          id
          title
          name
          path
          desc
          cover {
            childImageSharp {
              gatsbyImageData(width: 700, height: 560)
            }
          }
          nav {
            defaultEntry {
              path
            }
            sequence {
              key
              activityKeys
              optional
            }
          }
        }
      }
      allSurvey(sort: { fields: id, order: ASC }) {
        nodes {
          id
          title
          tint
          scope
          cover {
            childImageSharp {
              gatsbyImageData
            }
          }
          sections {
            key
            type
            required
            questions {
              key
              type
              cond
              required
            }
          }
        }
      }
    }
  `);

  return (
    <div>
      <SEO />
      <DashboardContent topics={topics} surveys={surveys} />
    </div>
  );
}
